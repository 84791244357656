export type MasseurList = {
	file: string
	name: string
	modal: boolean
}

export const useMasseurList = (): MasseurList[] => {
	return [
		{
			file: 'micaele_2025-01-14',
			name: 'Massoterapeuta Micaele',
			modal: true
		},
		{
			file: 'regiane_2025-02-17',
			name: 'Massoterapeuta Regiane',
			modal: true
		},
		{
			file: 'ana-paula_2025-03-06',
			name: 'Massoterapeuta Ana Paula',
			modal: true
		},
		{
			file: 'flavia_2025-02-17',
			name: 'Massoterapeuta Flávia',
			modal: true
		}
	]
}
